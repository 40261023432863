@mixin heading-base {
  margin: 0;
}

@mixin heading-h1 {
  @include heading-base;

  font: var(--mdrt-headline-1);
}

@mixin heading-h2 {
  @include heading-base;

  font: var(--mdrt-headline-2);
}

@mixin heading-h3 {
  @include heading-base;

  font: var(--mdrt-headline-3);
}

@mixin heading-h4 {
  @include heading-base;

  font: var(--mdrt-headline-4);
}
