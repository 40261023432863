@use '../index' as styles;
@use '../../../../../modules/shared/util-styling/mixins';

* {
  box-sizing: border-box;
  font-family: var(--font-family-primary);

  &:focus-visible {
    outline-offset: 4px;
  }
}

html {
  // cannot access css body variables at this level
  --header-height-with-submenu: 140px;

  font-size: 62.5%;

  @include mixins.transition {
    scroll-behavior: smooth;
  }

  @include mixins.breakpoint('medium') {
    scroll-padding-block-start: var(--header-height-with-submenu);
  }
}

body {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-body);
  min-height: 100vh;
  min-width: var(--container-min-width);
  position: relative;

  @include mixins.breakpoint('medium') {
    font-size: var(--font-size-m);
  }
}

svg {
  height: auto;
  width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
  text-decoration-color: transparent;

  @include mixins.link-hover;
}

select {
  border: none;
  border-right: 10px solid transparent;
  cursor: pointer;
  outline: 1px solid var(--temp-color-neutral-dark);
  padding: 4px 8px;

  &:focus {
    outline: 2px solid var(--temp-color-neutral-dark);
  }
}

p {
  line-height: 1.5;
  margin: 0;
}

.no-scroll {
  overflow: hidden;
}
