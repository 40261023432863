@use '../mixins';

h1 {
  @include mixins.heading-h1;
}

h2 {
  @include mixins.heading-h2;
}

h3 {
  @include mixins.heading-h3;
}

h4 {
  @include mixins.heading-h4;
}
