/**
 * Do not edit directly
 * Generated on Tue, 10 Dec 2024 12:18:28 GMT
 */

body {
  --mdrt-filter-button-icon-fill: #1c1c1ccc;
  --mdrt-filter-button-text-fill: #1c1c1ccc;
  --mdrt-filter-button-border-hover: #1c1c1c4d;
  --mdrt-filter-button-border-fill: #1c1c1c33;
  --mdrt-filter-button-bg-fill: #ffffff;
  --mdrt-mobile-page-navigation-bg-chevron: #1c1c1c;
  --mdrt-mobile-page-navigation-bg-selected-icon: #1c1c1cb3;
  --mdrt-mobile-page-navigation-bg-page-hover: #1c1c1c1a;
  --mdrt-mobile-page-navigation-bg-page: #ffffff;
  --mdrt-mobile-page-navigation-bg-page-selected: #1c1c1c0d;
  --mdrt-mobile-page-navigation-bg-text: #1c1c1c;
  --mdrt-mobile-page-navigation-bg-selector: #e8eef6;
  --mdrt-card-radius-small: 8px;
  --mdrt-card-radius-large: 16px;
  --mdrt-card-text-member-count: #1c1c1c;
  --mdrt-card-text-price: #1c1c1c;
  --mdrt-card-text-metadata: #1c1c1cb3;
  --mdrt-card-text-body: #1c1c1ccc;
  --mdrt-card-text-headline: #1c1c1c;
  --mdrt-card-bg-featured: #1853a3;
  --mdrt-card-bg-standard: #ffffff;
  --mdrt-input-border-thickness-focus: 2px;
  --mdrt-input-border-thickness-hover: 2px;
  --mdrt-input-size-icon: 24px;
  --mdrt-input-gap-label: 2px;
  --mdrt-input-gap-icon: 8px;
  --mdrt-input-border-radius: 8px;
  --mdrt-input-padding-horizontal: 12px;
  --mdrt-input-padding-vertical: 8px;
  --mdrt-input-text-read-only: #1c1c1c;
  --mdrt-input-text-placeholder: #1c1c1cb3;
  --mdrt-input-bg-disabled: #ffffff;
  --mdrt-input-border-read-only: #1c1c1c00;
  --mdrt-input-icon-disabled: #1c1c1c4d;
  --mdrt-input-bg-icon-enabled: #1c1c1ccc;
  --mdrt-input-bg-cursor: #1853a3;
  --mdrt-input-text-disabled: #1c1c1c4d;
  --mdrt-input-text-label-error: #db2318;
  --mdrt-input-text-value: #1c1c1c;
  --mdrt-input-text-label: #1c1c1cb3;
  --mdrt-input-border-disabled: #1c1c1c1a;
  --mdrt-input-border-error: #db2318;
  --mdrt-input-border-focus: #1853a3;
  --mdrt-input-border-hover: #1c1c1c4d;
  --mdrt-input-border-enabled: #1c1c1c4d;
  --mdrt-input-bg-hover: #ffffff;
  --mdrt-input-bg-read-only: #fafafa;
  --mdrt-input-bg-fill: #ffffff;
  --mdrt-modal-icon-size: 24px;
  --mdrt-modal-gap-between-header: 16px;
  --mdrt-modal-corner-radius: 16px;
  --mdrt-modal-vertical-padding-down-footer: 24px;
  --mdrt-modal-vertical-padding-top-footer: 8px;
  --mdrt-modal-vertical-padding-header: 16px;
  --mdrt-modal-horizontal-padding-footer: 24px;
  --mdrt-modal-horizontal-padding-header: 24px;
  --mdrt-modal-text-content-area: #1c1c1ccc;
  --mdrt-modal-text-header: #1c1c1c;
  --mdrt-modal-bg-fill: #ffffff;
  --mdrt-button-link-icon-size: 24px;
  --mdrt-button-link-bg-hover: #ffcf00;
  --mdrt-button-link-bg-underline: #ffcf00;
  --mdrt-button-link-text-hover-on-dark: #1c1c1c;
  --mdrt-button-link-text-hover: #1c1c1c;
  --mdrt-button-link-text-on-dark: #ffffff;
  --mdrt-button-link-text: #1c1c1c;
  --mdrt-alerts-text-non-semantic: #1c1c1ccc;
  --mdrt-alerts-bg-fill-non-semantic: #f4f4f4;
  --mdrt-alerts-gap-between: 8px;
  --mdrt-alerts-padding: 12px;
  --mdrt-alerts-corner-radius: 8px;
  --mdrt-alerts-icon-size: 24px;
  --mdrt-alerts-text-success: #066911;
  --mdrt-alerts-text-informational: #255391;
  --mdrt-alerts-text-error: #aa2019;
  --mdrt-alerts-bg-fill-success: #e5f3e7;
  --mdrt-alerts-bg-fill-informational: #eaf0f8;
  --mdrt-alerts-bg-fill-error: #fbe9e8;
  --mdrt-tooltip-corner-radius: 4px;
  --mdrt-tooltip-padding: 8px;
  --mdrt-tooltip-text-fill-inverse: #ffffff;
  --mdrt-tooltip-text-fill: #1c1c1c;
  --mdrt-tooltip-bg-fill-inverse: #1c1c1c;
  --mdrt-tooltip-bg-fill: #ffffff;
  --mdrt-icon-button-selected-bg-fill-dark-hover: #e8eef6;
  --mdrt-icon-button-selected-bg-fill-hover: #184d95;
  --mdrt-icon-button-selected-icon-fill-dark: #1853a3;
  --mdrt-icon-button-selected-icon-fill: #ffffff;
  --mdrt-icon-button-selected-bg-fill-dark: #ffffff;
  --mdrt-icon-button-selected-bg-fill: #1853a3;
  --mdrt-icon-button-size-small-corner-radius-small: 16px;
  --mdrt-icon-button-size-small-icon-size-small: 20px;
  --mdrt-icon-button-size-small-padding-small: 6px;
  --mdrt-icon-button-size-standard-corner-radius-standard: 20px;
  --mdrt-icon-button-size-standard-icon-size: 24px;
  --mdrt-icon-button-size-standard-padding: 8px;
  --mdrt-icon-button-secondary-icon-hover-dark: #ffffffcc;
  --mdrt-icon-button-secondary-icon-fill-dark: #ffffffb3;
  --mdrt-icon-button-secondary-bg-hover-dark: #ffffff33;
  --mdrt-icon-button-secondary-icon-hover: #1c1c1ccc;
  --mdrt-icon-button-secondary-icon-fill: #1c1c1cb3;
  --mdrt-icon-button-secondary-bg-hover: #1c1c1c1a;
  --mdrt-icon-button-primary-icon-hover-dark: #ffffffcc;
  --mdrt-icon-button-primary-icon-fill-dark: #ffffffb3;
  --mdrt-icon-button-primary-bg-hover-dark: #ffffff33;
  --mdrt-icon-button-primary-bg-fill-dark: #ffffff1a;
  --mdrt-icon-button-primary-icon-hover: #1c1c1ccc;
  --mdrt-icon-button-primary-icon-fill: #1c1c1cb3;
  --mdrt-icon-button-primary-bg-hover: #1c1c1c1a;
  --mdrt-icon-button-primary-bg-fill: #1c1c1c0d;
  --mdrt-icon-button-corner-radius: 20px;
  --mdrt-icon-button-icon-size: 24px;
  --mdrt-icon-button-padding: 8px;
  --mdrt-icon-button-icon-hover: #1c1c1ccc;
  --mdrt-icon-button-icon-fill: #1c1c1cb3;
  --mdrt-icon-button-bg-hover: #1c1c1c1a;
  --mdrt-icon-button-bg-fill: #1c1c1c0d;
  --mdrt-badge-clickable-text-fill-hover-on-primary: #ffffff;
  --mdrt-badge-clickable-text-fill-on-primary: #ffffffcc;
  --mdrt-badge-clickable-bg-fill-hover-on-primay: #ffffff33;
  --mdrt-badge-clickable-bg-fill-on-primary: #ffffff1a;
  --mdrt-badge-clickable-text-fill-hover: #1c1c1c;
  --mdrt-badge-clickable-text-fill: #1c1c1ccc;
  --mdrt-badge-clickable-bg-fill-hover: #1c1c1c1a;
  --mdrt-badge-clickable-bg-fill: #1c1c1c0d;
  --mdrt-badge-static-text-fill-on-primary: #ffffff;
  --mdrt-badge-static-bg-fill-on-primary: #18365f;
  --mdrt-badge-static-text-fill: #1c1c1c;
  --mdrt-badge-static-bg-fill: #ffffff;
  --mdrt-badge-icon-size: 16px;
  --mdrt-badge-corner-radius: 4px;
  --mdrt-badge-horizontal-padding-right-icon: 4px;
  --mdrt-badge-horizontal-padding: 4px;
  --mdrt-badge-vertical-padding: 2px;
  --mdrt-badge-text-fill-action-hover: #1c1c1c;
  --mdrt-badge-text-fill-action: #1c1c1ccc;
  --mdrt-badge-text-fill-static: #1c1c1c;
  --mdrt-badge-bg-fill-action-hover: #1c1c1c1a;
  --mdrt-badge-bg-fill-action: #1c1c1c0d;
  --mdrt-badge-bg-fill-static: #ffffff;
  --mdrt-chips-size-small-corner-radius: 16px;
  --mdrt-chips-size-small-gap-between-items: 8px;
  --mdrt-chips-size-small-icon-size: 20px;
  --mdrt-chips-size-small-horizontal-padding: 12px;
  --mdrt-chips-size-small-vertical-padding: 6px;
  --mdrt-chips-size-standard-gap-between-items: 8px;
  --mdrt-chips-size-standard-corner-radius: 24px;
  --mdrt-chips-size-standard-icon-size: 24px;
  --mdrt-chips-size-standard-horizontal-padding: 16px;
  --mdrt-chips-size-standard-vertical-padding: 8px;
  --mdrt-chips-filter-chips-text-hover: #1c1c1ccc;
  --mdrt-chips-filter-chips-text-fill: #1c1c1cb3;
  --mdrt-chips-filter-chips-bg-hover: #1c1c1c1a;
  --mdrt-chips-filter-chips-bg-fill: #1c1c1c0d;
  --mdrt-chips-action-chips-text-hover-high-emphasis: #1c1c1c;
  --mdrt-chips-action-chips-text-hover-on-dark: #1c1c1c;
  --mdrt-chips-action-chips-bg-hover-high-emphasis: #ffe059;
  --mdrt-chips-action-chips-bg-fill-high-emphasis: #ffcf00;
  --mdrt-chips-action-chips-text-fill-high-emphasis: #1c1c1c;
  --mdrt-chips-action-chips-text-fill-on-dark: #ffffff;
  --mdrt-chips-action-chips-text-hover: #1c1c1ccc;
  --mdrt-chips-action-chips-text-fill: #1c1c1cb3;
  --mdrt-chips-action-chips-bg-hover-on-dark: #ffffff;
  --mdrt-chips-action-chips-bg-fill-on-dark: #18365f;
  --mdrt-chips-action-chips-bg-hover: #1c1c1c1a;
  --mdrt-chips-action-chips-bg-fill: #1c1c1c0d;
  --mdrt-chips-select-chips-text-fill-selected: #ffffff;
  --mdrt-chips-select-chips-text-hover: #1c1c1ccc;
  --mdrt-chips-select-chips-text-fill: #1c1c1cb3;
  --mdrt-chips-select-chips-bg-hover-selected: #184d95;
  --mdrt-chips-select-chips-bg-fill-selected: #1853a3;
  --mdrt-chips-select-chips-bg-hover: #1c1c1c1a;
  --mdrt-chips-select-chips-bg-fill: #1c1c1c0d;
  --mdrt-chips-gap-between-items: 8px;
  --mdrt-chips-corner-radius: 24px;
  --mdrt-chips-icon-size: 24px;
  --mdrt-chips-horizontal-padding: 16px;
  --mdrt-chips-vertical-padding: 8px;
  --mdrt-chips-text-fill-selected: #ffffff;
  --mdrt-chips-text-hover: #1c1c1ccc;
  --mdrt-chips-text-fill: #1c1c1cb3;
  --mdrt-chips-bg-hover-selected: #184d95;
  --mdrt-chips-bg-fill-selected: #1853a3;
  --mdrt-chips-bg-hover: #1c1c1c1a;
  --mdrt-chips-bg-fill: #1c1c1c0d;
  --mdrt-buttons-size-small-gap-between: 8px;
  --mdrt-buttons-size-small-left-padding-leading-icon: 12px;
  --mdrt-buttons-size-small-horizontal-padding: 16px;
  --mdrt-buttons-size-small-vertical-padding: 6px;
  --mdrt-buttons-size-small-radius: 16px;
  --mdrt-buttons-size-small-icon-size: 20px;
  --mdrt-buttons-size-small-border-thickness: 1px;
  --mdrt-buttons-size-standard-border-thickness: 1px;
  --mdrt-buttons-size-standard-gap-between: 8px;
  --mdrt-buttons-size-standard-horizontal-padding: 24px;
  --mdrt-buttons-size-standard-vertical-padding: 8px;
  --mdrt-buttons-size-standard-radius: 24px;
  --mdrt-buttons-size-standard-icon-size: 24px;
  --mdrt-buttons-size-standard-left-padding-leading-icon-standard: 16px;
  --mdrt-buttons-gap-between: 8px;
  --mdrt-buttons-horizontal-padding: 24px;
  --mdrt-buttons-vertical-padding: 8px;
  --mdrt-buttons-radius: 24px;
  --mdrt-buttons-icon-size: 24px;
  --mdrt-buttons-border-thickness: 1px;
  --mdrt-buttons-left-padding-leading-icon: 16px;
  --mdrt-buttons-secondary-border-hover: #1c1c1cb3;
  --mdrt-buttons-secondary-border-fill: #1c1c1cb3;
  --mdrt-buttons-secondary-text-disabled: #1c1c1c4d;
  --mdrt-buttons-secondary-text-hover: #1c1c1c;
  --mdrt-buttons-secondary-text-enabled: #1c1c1c;
  --mdrt-buttons-secondary-bg-disabled: #1c1c1c0d;
  --mdrt-buttons-secondary-bg-hover: #1c1c1c1a;
  --mdrt-buttons-secondary-bg-fill: #ffffff;
  --mdrt-buttons-primary-border-hover: #ffe059;
  --mdrt-buttons-primary-border-fill: #ffcf00;
  --mdrt-buttons-primary-text-disabled: #1c1c1c4d;
  --mdrt-buttons-primary-text-hover: #1c1c1c;
  --mdrt-buttons-primary-text-enabled: #1c1c1c;
  --mdrt-buttons-primary-bg-disabled: #1c1c1c0d;
  --mdrt-buttons-primary-bg-hover: #ffe059;
  --mdrt-buttons-primary-bg-fill: #ffcf00;
  --mdrt-paragraph-indent-0: 0px;
  --mdrt-text-decoration-none: none;
  --mdrt-text-case-none: none;
  --mdrt-special-text-button-3: 900 1.4rem/1.16 Merriweather;
  --mdrt-special-text-button-2: 900 1.6rem/1.25 Merriweather;
  --mdrt-special-text-input-label: 600 1.2rem/1.33 'Nunito Sans';
  --mdrt-special-text-button-1: 900 2rem/1.2 Merriweather;
  --mdrt-body-3-short: 400 1.6rem/1.25 'Nunito Sans';
  --mdrt-body-3-long: 400 1.6rem/1.5 'Nunito Sans';
  --mdrt-body-4: 400 1.4rem/1.42 'Nunito Sans';
  --mdrt-body-2: 400 2rem/1.2 'Nunito Sans';
  --mdrt-body-1: 400 2.4rem/1.33 'Nunito Sans';
  --mdrt-title-4: 600 1.4rem/1.42 'Nunito Sans';
  --mdrt-title-3: 600 1.6rem/1.5 'Nunito Sans';
  --mdrt-title-2: 600 2rem/1.2 'Nunito Sans';
  --mdrt-title-1: 600 2.4rem/1.33 'Nunito Sans';
  --mdrt-headline-4: 700 2rem/1.2 Merriweather;
  --mdrt-headline-3: 700 2.4rem/1.33 Merriweather;
  --mdrt-headline-2: 700 3.2rem/1.25 Merriweather;
  --mdrt-headline-1: 700 4rem/1.4 Merriweather;
  --mdrt-paragraph-spacing-0: 16;
  --mdrt-letter-spacing-0: 0em;
  --mdrt-font-size-6: 4rem;
  --mdrt-font-size-5: 3.2rem;
  --mdrt-font-size-4: 2.4rem;
  --mdrt-font-size-3: 2rem;
  --mdrt-font-size-2: 1.6rem;
  --mdrt-font-size-1: 1.4rem;
  --mdrt-font-size-0: 1.2rem;
  --mdrt-font-weights-merriweather-3: 900;
  --mdrt-font-weights-nunito-sans-2: 400;
  --mdrt-font-weights-nunito-sans-1: 600;
  --mdrt-font-weights-merriweather-0: 700;
  --mdrt-line-heights-7: 1.2;
  --mdrt-line-heights-6: 1.16;
  --mdrt-line-heights-5: 1.42;
  --mdrt-line-heights-4: 1.5;
  --mdrt-line-heights-3: 1.2;
  --mdrt-line-heights-2: 1.33;
  --mdrt-line-heights-1: 1.25;
  --mdrt-line-heights-0: 1.4;
  --mdrt-font-families-nunito-sans: 'Nunito Sans';
  --mdrt-font-families-merriweather: Merriweather;
  --mdrt-elevation-02: 0 8px 20px 0 #0000001f, 0 6px 12px 0 #00000014, 0 2px 8px 0 #00000029;
  --mdrt-elevation-01: 0 4px 16px 0 #0000001f, 0 2px 8px 0 #00000014, 0 1px 2px 0 #00000029;
  --mdrt-size-xxl: 48px;
  --mdrt-size-xl: 40px;
  --mdrt-size-s: 20px;
  --mdrt-size-l: 32px;
  --mdrt-size-xs: 16px;
  --mdrt-size-m: 24px;
  --mdrt-corner-radius-xl: 20px;
  --mdrt-corner-radius-xs: 2px;
  --mdrt-corner-radius-s: 4px;
  --mdrt-corner-radius-l: 16px;
  --mdrt-corner-radius-none: 0px;
  --mdrt-corner-radius-m: 8px;
  --mdrt-corner-radius-xxl: 24px;
  --mdrt-corner-radius-maximum: 999px;
  --mdrt-border-thickness-04: 6px;
  --mdrt-border-thickness-03: 4px;
  --mdrt-border-thickness-02: 2px;
  --mdrt-border-thickness-01: 1px;
  --mdrt-spacing-06: 6px;
  --mdrt-spacing-08: 8px;
  --mdrt-spacing-04: 4px;
  --mdrt-spacing-02: 2px;
  --mdrt-spacing-72: 72px;
  --mdrt-spacing-56: 56px;
  --mdrt-spacing-32: 32px;
  --mdrt-spacing-24: 24px;
  --mdrt-spacing-20: 20px;
  --mdrt-spacing-16: 16px;
  --mdrt-spacing-12: 12px;
  --mdrt-color-borders-none: #1c1c1c00;
  --mdrt-color-borders-dark-solid: #1c1c1c;
  --mdrt-color-borders-light-01: #ffffff;
  --mdrt-color-borders-dark-01: #1c1c1c1a;
  --mdrt-color-borders-focus: #1853a3;
  --mdrt-color-borders-error: #db2318;
  --mdrt-color-borders-dark-04: #1c1c1cb3;
  --mdrt-color-borders-dark-02: #1c1c1c33;
  --mdrt-color-borders-dark-03: #1c1c1c4d;
  --mdrt-color-backgrounds-bg-image: #3962a0;
  --mdrt-color-backgrounds-bg-loader: #ffffffcc;
  --mdrt-color-backgrounds-bg-surface-inverse-04: #494949;
  --mdrt-color-backgrounds-bg-surface-04: #e8e8e8;
  --mdrt-color-backgrounds-bg-informational: #2a66b8;
  --mdrt-color-backgrounds-bg-success: #00840d;
  --mdrt-color-backgrounds-bg-error: #db2318;
  --mdrt-color-backgrounds-bg-informational-surface: #eaf0f8;
  --mdrt-color-backgrounds-bg-informational-fill: #255391;
  --mdrt-color-backgrounds-bg-primary-fill-03: #18365f;
  --mdrt-color-backgrounds-bg-primary-fill-02: #184481;
  --mdrt-color-backgrounds-bg-success-fill: #066911;
  --mdrt-color-backgrounds-bg-success-surface: #e5f3e7;
  --mdrt-color-backgrounds-bg-error-fill: #aa2019;
  --mdrt-color-backgrounds-bg-error-surface: #fbe9e8;
  --mdrt-color-backgrounds-bg-neutral-fill-inverse: #ffffff1a;
  --mdrt-color-backgrounds-bg-neutral-hover-inverse: #ffffff33;
  --mdrt-color-backgrounds-bg-primary-selected: #e8eef6;
  --mdrt-color-backgrounds-bg-disabled-fill: #1c1c1c0d;
  --mdrt-color-backgrounds-bg-neutral-hover: #1c1c1c1a;
  --mdrt-color-backgrounds-bg-neutral-fill: #1c1c1c0d;
  --mdrt-color-backgrounds-bg-tertiary-hover: #a9eaf8;
  --mdrt-color-backgrounds-bg-tertiary-fill: #7adff4;
  --mdrt-color-backgrounds-bg-secondary-hover: #ffe059;
  --mdrt-color-backgrounds-bg-secondary-fill: #ffcf00;
  --mdrt-color-backgrounds-bg-primary-hover: #184d95;
  --mdrt-color-backgrounds-bg-primary-fill-01: #1853a3;
  --mdrt-color-backgrounds-bg-surface-inverse-03: #494949;
  --mdrt-color-backgrounds-bg-surface-inverse-02: #333333;
  --mdrt-color-backgrounds-bg-surface-inverse: #1c1c1c;
  --mdrt-color-backgrounds-bg-surface-03: #f4f4f4;
  --mdrt-color-backgrounds-bg-surface-02: #fafafa;
  --mdrt-color-backgrounds-bg-surface: #ffffff;
  --mdrt-color-text-on-dark-error: #fbe9e8;
  --mdrt-color-text-on-dark-action: #c5d4e8;
  --mdrt-color-text-on-dark-disabled: #ffffff66;
  --mdrt-color-text-on-dark-placeholder: #ffffff99;
  --mdrt-color-text-on-dark-tertiary: #ffffffb3;
  --mdrt-color-text-on-dark-secondary: #ffffffcc;
  --mdrt-color-text-on-dark-primary: #ffffff;
  --mdrt-color-text-on-light-error: #db2318;
  --mdrt-color-text-on-light-action: #1853a3;
  --mdrt-color-text-on-light-disabled: #1c1c1c4d;
  --mdrt-color-text-on-light-placeholder: #1c1c1cb3;
  --mdrt-color-text-on-light-tertiary: #1c1c1cb3;
  --mdrt-color-text-on-light-secondary: #1c1c1ccc;
  --mdrt-color-text-on-light-primary: #1c1c1c;
}
