// Overrides for vidstack player

body {
  --media-font-family: var(--font-family-primary), sans-serif;
  --media-time-font-size: var(--font-size-xs, 14px);
  --video-font-family: var(--font-family-primary), sans-serif;
  --audio-font-family: var(--font-family-primary), sans-serif;
  --audio-time-font-size: var(--font-size-xs, 14px);
}

[class*='vds-'] {
  svg {
    height: var(--player-icon-size, 24px);
    width: var(--player-icon-size, 24px);
  }
}

.vds-settings-menu-items {
  backdrop-filter: none;
}
