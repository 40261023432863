@use '../../../../../modules/shared/util-styling/mixins';

.table-section {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table-wrapper {
  overflow: auto;
  width: 100%;
}

.table {
  border-collapse: collapse;
  font-size: var(--font-size-s);
  margin-bottom: var(--spacing-20);
  margin-top: var(--spacing-40);
  width: 100%;

  tr,
  td {
    padding: var(--spacing-8) var(--spacing-16);
    vertical-align: middle;
  }

  tr {
    border-block: 1px solid var(--temp-color-neutral-dark);
    border-inline: 1px solid var(--temp-color-neutral-light-40);
  }

  td {
    white-space: nowrap;
  }

  &__header {
    tr {
      border-block: 2px solid var(--temp-color-neutral-dark);
      border-inline: none;
      font-weight: 700;
      text-transform: uppercase;
    }

    td:not(:last-child) {
      position: relative;

      &::before {
        background-color: var(--temp-color-neutral-dark);
        content: '';
        height: 20px;
        position: absolute;
        right: 0;
        width: 1px;
      }
    }
  }

  &__no-results {
    padding: var(--spacing-20);
  }
}

.row {
  &__clickable {
    cursor: pointer;
    transition: background-color var(--animation-duration) ease;

    &:hover {
      background-color: var(--temp-color-neutral-light-20);
    }
  }

  &--collapsible-expanded {
    display: table-row;
  }

  &--collapsible-collapsed {
    display: none;
  }
}

.row-data {
  font-family: var(--font-family-table-data);
  white-space: pre;
  width: 100%;
  word-break: break-all;
}

tr:nth-child(even) {
  background-color: var(--temp-color-neutral-light-10);
}

.cell {
  &__action-button {
    height: 16px;
    width: 16px;

    &--active {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &--right {
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }
}

@include mixins.breakpoint('medium') {
  .table-wrapper {
    overflow-x: initial;
  }
}
